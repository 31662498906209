import React, { Component } from "react";
import { Link } from "gatsby";
import locales from "../constants";

import GooglePartner from "../components/svg/GooglePartner";

class Header extends Component {
	static defaultProps = {
		icons: "#721aad",
		navClass: "default",
		activeClass: "position_top",
	};
	state = {
		isMenuOpen: false,
	};

	constructor(props) {
		super(props);
		this.escFunction = this.escFunction.bind(this);
		this.handleCloseMenu = this.handleCloseMenu.bind(this);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState((isMenuOpen) => {
				return { isMenuOpen: false };
			});
		}
	}
	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", () => {
				let activeClass = "shrink";
				if (window.scrollY === 0) {
					activeClass = "position_top";
				}
				this.setState({ activeClass });
			});
		}
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
	}

	handleCloseMenu() {
		this.setState((isMenuOpen) => {
			return { isMenuOpen: false };
		});
	}

	render() {
		const { isMenuOpen } = this.state;
		let { lang, icons, background, navClass } = this.props;
		const translation = locales[lang];
		const { title, city, street } = translation.address;
		if (typeof window !== "undefined") {
			if (isMenuOpen === true) {
				document.body.classList.add("appleFix");
			} else {
				document.body.classList.remove("appleFix");
			}
		}
		return (
			<>
				<span id="top" />
				<header
					className={
						isMenuOpen
							? `menu_open ${this.state.activeClass}`
							: `${this.state.activeClass}`
					}
				>
					<div className="navbar_brand">
						<div className="navbar_brand--inner">
							<Link
								to={`/${lang === "pl" ? "" : lang}`}
								aria-label="Home Page"
								onClick={() =>
									this.setState((prevState) => {
										return { isMenuOpen: false };
									})
								}
							>
								<svg
									version="1.1"
									id="svg2"
									className="logo-text"
									viewBox="0 0 697.70667 206.58667"
									width="97px"
									height="30px"
								>
									<defs id="defs6" />
									<g
										id="g10"
										transform="matrix(1.3333333,0,0,-1.3333333,0,206.58667)"
									>
										<g id="g12" transform="scale(0.1)">
											<path
												d="M 1650.81,1113.46 1351.33,23.9297 H 1024.7 L 923.441,390.488 825.406,832.141 v 0 L 727.27,390.488 626.207,23.9297 H 299.578 L 0,1113.46 h 281.516 l 91.105,-342.64 97.258,-437.851 95.105,437.851 91.204,342.64 h 338.73 l 91.002,-342.64 95.31,-433.941 97.06,433.941 91.1,342.64 h 281.42"
												id="path14"
												fill="#662d97"
											/>
											<path
												d="m 3515.46,903.625 c 136.7,0 225.75,-81.34 257.49,-200.187 h -521.14 c 35.74,116.992 125.08,200.187 263.65,200.187 z m 6.04,-669.656 c -146.46,0 -265.49,87.101 -283.45,261.492 h 836.33 c 27.54,358.555 -196.46,641.929 -556.97,641.929 -332.68,0 -568.8,-243.824 -568.8,-570.55 C 2948.61,241.969 3182.77,0 3523.46,0 c 233.67,0 451.71,112.98 532.86,370.672 H 3763.09 C 3725.59,283.469 3630.38,233.969 3521.5,233.969"
												fill="#662d97"
												id="path16"
											/>
											<path
												d="m 4840.51,1137.39 c -338.73,0 -622.2,-641.929 -622.2,-1113.4603 h 283.47 V 596.43 c 0,178.297 85.05,271.55 243.43,271.55 148.72,0 204.08,-79.289 204.08,-222.042 V 23.9297 h 283.46 V 707.543 c 0,267.359 -148.61,429.847 -392.24,429.847"
												fill="#662d97"
												id="path18"
											/>
											<path
												d="m 4218.31,829.996 c 156.33,0 283.47,126.938 283.47,283.464 H 4218.31 V 829.996"
												fill="#662d97"
												id="path20"
											/>
											<path
												d="m 2386.38,1137.39 c -338.74,0 -622.2,-641.929 -622.2,-1113.4603 h 283.46 V 596.43 c 0,178.297 85.05,271.55 243.43,271.55 148.72,0 204.08,-79.289 204.08,-222.042 V 23.9297 h 283.46 V 707.543 c 0,267.359 -148.61,429.847 -392.23,429.847"
												fill="#662d97"
												id="path22"
											/>
											<path
												d="M 1764.18,1549.45 V 829.996 c 156.33,0 283.46,126.938 283.46,283.464 v 435.99 h -283.46"
												fill="#662d97"
												id="path24"
											/>
										</g>
									</g>
								</svg>
							</Link>
							<a
								href="https://www.google.com/partners/agency?id=8558321192"
								target="_blank"
								rel="noreferrer"
							>
								<GooglePartner
									isMenuOpen={isMenuOpen}
									activeClass={this.state.activeClass}
								/>
							</a>
						</div>
					</div>
					<div>
						<Link
							to="/brief/"
							className="btn btn-box free-consulting"
						>
							Bezpłatna konsultacja
						</Link>
						<button
							type="button"
							aria-label="Open Menu"
							className={`hamburger hamburger--squeeze ${
								isMenuOpen ? "is-active" : ""
							}`}
							onClick={() =>
								this.setState((prevState) => {
									return {
										isMenuOpen: !prevState.isMenuOpen,
									};
								})
							}
						>
							{Array(4)
								.fill(null)
								.map((item, index) => (
									<span
										key={index}
										style={{
											background: !isMenuOpen
												? icons
												: "#fff",
										}}
									/>
								))}
						</button>
					</div>
				</header>
				<nav
					className={`nav-animation ${isMenuOpen ? "open" : ""}`}
					style={{
						backgroundImage: background,
					}}
				>
					{/* <div className="lines-container">
            <span className="line line--white line--medium" id="line-1"></span>
            <span className="line line--turqoise line--big" id="line-2"></span>
            <span className="line line--violet line--small" id="line-3"></span>
            <span className="line line--violet line--small" id="line-4"></span>
            <span className="line line--turqoise line--big" id="line-5"></span>
            <span className="line line--violet line--medium" id="line-6"></span>
            <span className="line line--violet line--medium" id="line-7"></span>
            <span className="line line--turqoise line--big" id="line-8"></span>
            <span className="line line--white line--small" id="line-9"></span>
            <span className="line line--violet line--big" id="line-10"></span>
            <span className="line line--turqoise line--medium" id="line-11"></span>
            <span className="line line--violet line--small" id="line-12"></span>
            <span className="line line--violet line--big" id="line-13"></span>
            <span className="line line--white line--small" id="line-14"></span>
          </div> */}
					<div className="wrapper">
						<ul className={`nav_list ${navClass}`}>
							{translation.header_menu.map(
								({ title, link, className }) => (
									<li key={title}>
										<Link
											to={link}
											activeClassName="active"
											onClick={this.handleCloseMenu}
											className={className}
										>
											{title}
										</Link>
									</li>
								)
							)}
						</ul>
						<div className="nav_info">
							<div className="row">
								<div className="col-sm-7">
									<p>{title}</p>
									<address>
										WHEN Sp. z o.o. <br />
										{street} <br /> {city}
									</address>
								</div>
								<div className="col-sm-5">
									<p>Kontakt</p>
									<a href="tel:+48790770628" className="link">
										+48 790 770 628
									</a>
									<a
										href="mailto:hello@when.pl "
										className="link"
									>
										hello@when.pl
									</a>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</>
		);
	}
}

export default Header;
