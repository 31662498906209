import React from 'react';

const CaseStudyHeader = (props) => {
    return (
        <div className="case-single-header">
            <h1 className="case-single-header__title" dangerouslySetInnerHTML={{ __html: props.title }} />
        </div>
    )
}

export default CaseStudyHeader;