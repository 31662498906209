import React, { useState } from "react";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import axios from "axios";
import Confetti from "react-dom-confetti";
import { Input, Check, Textarea } from "../components";

const ContactForm = ({ locales, thankYouTarget }) => {
	const { register, errors, handleSubmit, triggerValidation } = useForm({
		mode: "onChange",
	});
	const config = {
		angle: 90,
		spread: 100,
		startVelocity: 60,
		elementCount: 150,
		dragFriction: 0.1,
		duration: 2000,
		stagger: 0,
		width: "7px",
		height: "10px",
		colors: [
			"#E68F17",
			"#FAB005",
			"#FA5252",
			"#E64980",
			"#BE4BDB",
			"#0B7285",
			"#15AABF",
			"#EE1233",
			"#40C057",
		],
	};

	const [isLoading, setLoading] = useState(false);
	const [showConfetti, triggerConfetti] = useState(false);

	const isBrowser = typeof window !== "undefined";
	const pageName = isBrowser ? document.title : null;

	const onSubmit = (data, e) => {
		if (data.bot_field) return false;
		delete data.bot_field;
		triggerConfetti(true);
		setLoading(true);
		setTimeout(() => {
			axios
				.post(
					"https://adream-mailing.herokuapp.com/send-email-when",
					data
				)
				.then((res) => {
					setLoading(false);
					triggerConfetti(false);
					e.target.reset();
					console.log("Wysłane..");
					navigate(
						thankYouTarget
							? `/${thankYouTarget}/`
							: "/dziekujemy-za-kontakt"
					);
				})
				.catch((error) => {
					setLoading(false);
					console.log(`Błąd wysyłania.. ${error}`);
				});
		}, 1500);
	};

	return (
		<div className="wpcf7">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="hidden">
					<Input
						type="hidden"
						name="pageTitle"
						value={pageName}
						register={register}
					/>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="input-wrapper">
							<Input
								type="text"
								name="name"
								label="Imię i Nazwisko"
								register={register}
								validation={{
									required: locales.required,
									message: locales.email_error,
								}}
								errors={errors}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="input-wrapper">
							<Input
								type="email"
								name="email"
								label="E-mail"
								register={register}
								validation={{
									required: locales.required,
									message: locales.email_error,
								}}
								errors={errors}
								className="light-input"
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="input-wrapper">
							<Input
								type="tel"
								name="phone"
								label="Telefon"
								register={register}
								validation={{
									required: locales.required,
									message: locales.email_error,
								}}
								errors={errors}
								className="light-input"
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="input-wrapper">
							<Input
								type="text"
								name="website"
								label="Strona www"
								register={register}
								validation={{
									message: locales.email_error,
								}}
								errors={errors}
								className="light-input"
							/>
						</div>
					</div>
					<div className="col-md-12">
						<Textarea
							cols="40"
							rows="6"
							name="message"
							label={locales.message}
							register={register}
							validation={{
								required: locales.required,
								message: locales.email_error,
							}}
							errors={errors}
							className="light-input"
						/>
					</div>
					<div className="col-md-12">
						<Check
							register={register}
							name="term1"
							size="sm"
							validation={{ required: locales.required }}
							errors={errors}
							label={
								<p>
									Wyrażam zgodę na przetwarzanie moich danych
									osobowych przez When Sp. z o.o. z siedzibą w
									Krakowie, ul. Chopina 18/3a (Administrator
									danych) w celu udzielenia mi dodatkowych
									informacji handlowych z zakresu oferty
									Agencji. Podanie danych jest dobrowolne i
									umożliwia uzyskanie informacji handlowej.
								</p>
							}
						/>
						<Check
							register={register}
							name="term2"
							size="sm"
							validation={{ required: locales.required }}
							errors={errors}
							label={
								<p>
									Wyrażam zgodę na przetwarzanie moich danych
									osobowych przez When Sp. z o.o. z siedzibą w
									Krakowie, ul. Chopina 18/3a (Administrator
									danych), w celu marketingu bezpośredniego
									dotyczącego własnych produktów i usług.
								</p>
							}
						/>
						<Check
							register={register}
							name="term3"
							size="sm"
							errors={errors}
							label={
								<p>
									Wyrażam zgodę na udostępnienie moich danych
									osobowych przez When sp. z o. o., z siedzibą
									w Krakowie, ul. Chopina 18/3a, KRS:
									0000683438 jako administratora moich danych
									osobowych, podmiotowi: Agencja Interaktywna
									ADream z siedzibą w Krakowie, ul. Chopina
									18/3a, KRS: 0000517132 (kolejnemu
									Administratorowi danych), w celu udzielenia
									mi dodatkowych informacji handlowych z
									zakresu oferty świadczonej przez Agencję
									Interaktywną ADream. <br />
									Podanie danych jest dobrowolne i umożliwia
									uzyskanie informacji handlowej.*
								</p>
							}
						/>
						<div className="btn_wrapper">
							<button
								type="submit"
								aria-label="Wyślij"
								className="wpcf7-form-control wpcf7-submit btn btn-line"
								onClick={() => {
									triggerValidation();
								}}
							>
								{isLoading ? "wysyłanie..." : "wyślij"}
							</button>
							<Confetti active={showConfetti} config={config} />
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ContactForm;
