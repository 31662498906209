import React, { Component } from "react";
import _ from "lodash";

const clientsFront = [
	{ src: require("../assets/img/clients-home/logo-home-gmr.png") },
	{ src: require("../assets/img/clients-home/logo-home-greenpoint.png") },
	{ src: require("../assets/img/clients-home/logo-home-imperial.png") },
	{ src: require("../assets/img/clients-home/logo-home-komputronik.png") },
	{ src: require("../assets/img/clients-home/logo-home-krakow.png") },
	{ src: require("../assets/img/clients-home/logo-home-steelseries.png") },
	{ src: require("../assets/img/clients-home/logo-home-techland.png") },
	{ src: require("../assets/img/clients-home/logo-home-thailand.png") },
];
const clientsBack = [
	{ src: require("../assets/img/clients-home/logo-home-bmr.png") },
	{ src: require("../assets/img/clients-home/logo-home-bialaperla.png") },
	{ src: require("../assets/img/clients-home/logo-home-dombud.png") },
	{ src: require("../assets/img/clients-home/logo-home-empik.png") },
	{ src: require("../assets/img/clients-home/logo-home-ibis.png") },
	{ src: require("../assets/img/clients-home/logo-home-mediaarena.png") },
	{ src: require("../assets/img/clients-home/logo-home-pigment.png") },
	{ src: require("../assets/img/clients-home/logo-home-speakup.png") },
];

class HomeClients extends Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [1, 2, 3, 4, 5, 6, 7, 8],
			imageOrders: [0, 1, 2, 3, 4, 5, 6, 7],
			animationNumber: 0,
		};
		this.interval = null;
		this.timeout = null;
		this.animationTimeout = null;
	}

	componentDidMount() {
		this.interval = setInterval(() => {
			this.animation();
		}, 8000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		clearTimeout(this.timeout);
		clearTimeout(this.animationTimeout);
	}

	animation = () => {
		this.setState(
			(prevState) => {
				return {
					...prevState,
					animationNumber: prevState.animationNumber + 1,
				};
			},
			() => {
				let { orders, imageOrders, animationNumber } = this.state;
				this.runAnimation(
					_.shuffle(orders),
					_.shuffle(imageOrders),
					animationNumber
				);
				this.props.onClientAnimation();
			}
		);
	};

	runAnimation = (order, imgOrder, animationNumber) => {
		order.forEach(
			(item, index) =>
				(this.timeout = setTimeout(() => {
					const element = document.getElementById(`image-${item}`);
					if (!element) return false;
					element.style.transform = "scale(0)";
					this.animationTimeout = setTimeout(() => {
						element.src =
							animationNumber % 2 === 0
								? clientsFront[imgOrder[index]].src
								: clientsBack[imgOrder[index]].src;
						element.style.transform = "scale(1)";
					}, 250);
				}))
		);
	};

	render() {
		return (
			<div className="custom_row_wrapper">
				<div className="row custom_row">
					{clientsFront.map(({ src }, index) => (
						<div className="col-md-3 col-6" key={index}>
							<img
								className="img-fluid"
								src={src}
								id={`image-${index + 1}`}
								alt=""
								style={{ transition: "0.2s" }}
							/>
						</div>
					))}
				</div>
			</div>
		);
	}
}

export default HomeClients;
