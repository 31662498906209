import React, { Component } from "react";

class Textarea extends Component {
  state = {
    filled: false,
  };

  handleFocus = () => this.setState({ filled: true });

  checkInput = ({ target: { value } }) => {
    if (value.length > 0) {
      this.setState({ filled: true });
    } else {
      this.setState({ filled: false });
    }
  };
  render() {
    const {
      type,
      name,
      cols,
      rows,
      label,
      register,
      value,
      validation,
      errors,
      className,
    } = this.props;
    const { filled } = this.state;
    return (
      <div className={`inp-group ${filled ? "fill" : ""}`}>
        {/* eslint-disable no-useless-concat */}
        <label
          htmlFor={name}
          className={
            validation && validation.required
              ? "required" + " " + className
              : className
          }
        >
          {label}
        </label>
        <textarea
          onFocus={this.handleFocus}
          onBlur={this.checkInput}
          cols={cols}
          rows={rows}
          type={type}
          name={name}
          aria-label="textarea"
          autoComplete="off"
          ref={register(validation || {})}
          defaultValue={value || ""}
          className={className}
        />
        {errors && errors[name] && (
          <span role="alert" className="error">
            {errors[name].message}
          </span>
        )}
      </div>
    );
  }
}

export default Textarea;
