import React from "react";
import { Link } from "gatsby";
import locales from "../constants";

const Footer = ({ lang }) => {
	const translation = locales[lang];
	const { title } = translation.address;
	return (
		<>
			<footer>
				<div className="row">
					<div className="col-md-6 menu-col">
						<p>MENU</p>
						<div className="menu-container">
							<ul className="list_nav">
								<li>
									<Link
										to="/win-win/"
										className="win-win-footer"
									>
										Filozofia
									</Link>
								</li>
								<li>
									<Link
										to="/uslugi/"
										className="uslugi-footer"
									>
										Usługi
									</Link>
								</li>
								<li>
									<Link
										to="/case-study/"
										className="case-studies-footer"
									>
										Case studies
									</Link>
								</li>
								<li>
									<Link
										to="/referencje/"
										className="referencje-footer"
									>
										Referencje
									</Link>
								</li>
							</ul>
							<ul className="list_nav list_nav--second">
								<li>
									<Link
										to="/roi-masters/"
										className="roi-masters-footer"
									>
										ROI Masters
									</Link>
								</li>
								<li>
									<Link to="/blog/" className="blog-footer">
										Blog
									</Link>
								</li>
								<li>
									<Link to="/praca/" className="praca-footer">
										Praca
									</Link>
								</li>
								<li>
									<Link
										to="/kontakt/"
										className="kontakt-footer"
									>
										Kontakt
									</Link>
								</li>
								<li>
									<Link to="/brief/" className="brief-footer">
										Brief
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-6 mobile-hide">
								<p>{title}</p>
								<img
									className="img-fluid when-logo"
									src={require("../assets/images/logo-new-full-white.svg")}
									alt="When"
								/>
								<address>
									WHEN Sp. z o.o. <br />
									ul. Chopina 18 <br />
									30-047 Kraków
								</address>
							</div>
							<div className="col-md-6">
								<p>Kontakt</p>
								<a href="tel:+48790770628" className="link">
									+48 790 770 628
								</a>
								<a
									href="mailto:hello@when.pl "
									className="link"
								>
									hello@when.pl
								</a>
								<ul className="list_social">
									<li>
										<a
											href="https://www.facebook.com/agencjawhen"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												className="img-fluid"
												src={require("../assets/images/ico_fb.svg")}
												alt="ADream Facebook"
											/>
										</a>
									</li>
									<li>
										<a
											href="https://www.instagram.com/when.pl/"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												className="img-fluid"
												src={require("../assets/images/ico_inst.svg")}
												alt="ADream Facebook"
											/>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="row bottom_row row align-items-end flex-column-reverse flex-lg-row row_sec">
					<div className="col-md-6 left-col">
						<div className="logo-wrapper">
							<a
								href="https://adream.pl/"
								target="_blank"
								rel="noreferrer"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									version="1.1"
									x="0px"
									y="0px"
									viewBox="0 0 149 39"
									className="svg footer_logo replaced-svg"
								>
									<g id="Group_137" data-name="Group 137">
										<path
											id="Path_59"
											data-name="Path 59"
											d="M134.2,15.171h0a2.1,2.1,0,0,1-2.059-2.058h0a2.1,2.1,0,0,1,2.059-2.059h0a2.1,2.1,0,0,1,2.059,2.059h0A2.055,2.055,0,0,1,134.2,15.171Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_60"
											data-name="Path 60"
											d="M128.685,15.171h0a2.1,2.1,0,0,1-2.059-2.058h0a2.1,2.1,0,0,1,2.059-2.059h0a2.1,2.1,0,0,1,2.059,2.059h0A2.01,2.01,0,0,1,128.685,15.171Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_61"
											data-name="Path 61"
											d="M134.2,20.686h0a2.1,2.1,0,0,1-2.059-2.059h0a2.1,2.1,0,0,1,2.059-2.059h0a2.1,2.1,0,0,1,2.059,2.059h0A2.1,2.1,0,0,1,134.2,20.686Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_62"
											data-name="Path 62"
											d="M139.641,20.686h0a2.1,2.1,0,0,1-2.059-2.059h0a2.1,2.1,0,0,1,2.059-2.059h0a2.1,2.1,0,0,1,2.059,2.059h0A2.055,2.055,0,0,1,139.641,20.686Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_63"
											data-name="Path 63"
											d="M139.641,26.127h0a2.1,2.1,0,0,1-2.059-2.059h0a2.1,2.1,0,0,1,2.059-2.058h0a2.1,2.1,0,0,1,2.059,2.058h0A2.01,2.01,0,0,1,139.641,26.127Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_64"
											data-name="Path 64"
											d="M139.641,15.171h0a2.1,2.1,0,0,1-2.059-2.058h0a2.1,2.1,0,0,1,2.059-2.059h0a2.1,2.1,0,0,1,2.059,2.059h0A2.01,2.01,0,0,1,139.641,15.171Z"
											fill="#45e5d9"
										/>
									</g>
									<g id="Group_138" data-name="Group 138">
										<path
											id="Path_65"
											data-name="Path 65"
											d="M40.744,38.333H37.876c-.367,0-.441-.22-.367-.588l4.706-17.794a.886.886,0,0,1,.882-.588h3.971c.514,0,.808.22.882.588l4.632,17.794c.147.441,0,.588-.367.588H49.347a.617.617,0,0,1-.441-.147.7.7,0,0,1-.147-.515l-.809-3.235H42.215l-.883,3.309c-.073.22-.073.368-.22.515C41.112,38.333,40.965,38.333,40.744,38.333Zm4.265-15.368L42.876,31.5h4.412l-2.132-8.53Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_66"
											data-name="Path 66"
											d="M72.656,38.333h-2.5c-.441,0-.588-.22-.588-.588V23.921c0-.441.22-.588.588-.588h2.5c.441,0,.588.221.588.588v1.03h.147a2.06,2.06,0,0,1,1.177-1.177,3.7,3.7,0,0,1,1.764-.441h1.03a.541.541,0,0,1,.588.588V25.98a1.068,1.068,0,0,1-.147.515.413.413,0,0,1-.441.073H75.3c-1.4,0-2.059.736-2.059,2.28v8.9C73.318,38.186,73.1,38.333,72.656,38.333Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_67"
											data-name="Path 67"
											d="M91.038,38.333H84.494a4.98,4.98,0,0,1-3.6-1.25,4.584,4.584,0,0,1-1.25-3.6V28.039a5.1,5.1,0,0,1,1.25-3.6,5.1,5.1,0,0,1,3.6-1.25h2.353a4.98,4.98,0,0,1,3.6,1.25,4.584,4.584,0,0,1,1.25,3.6v3.824c0,.441-.221.588-.588.588H83.685c-.22,0-.367.073-.367.294v.662a2.186,2.186,0,0,0,.367,1.323,1.787,1.787,0,0,0,1.324.368h5.956c.441,0,.588.22.588.662v1.985a.624.624,0,0,1-.147.441C91.406,38.333,91.259,38.333,91.038,38.333Zm-7.353-8.382h4.044c.221,0,.3-.074.3-.294v-1.4a1.9,1.9,0,0,0-.368-1.324,2.03,2.03,0,0,0-1.324-.368h-1.25a1.89,1.89,0,0,0-1.323.368,2.03,2.03,0,0,0-.368,1.324v1.4C83.318,29.877,83.465,29.951,83.685,29.951Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_68"
											data-name="Path 68"
											d="M99.641,38.333h-.735a4.98,4.98,0,0,1-3.6-1.25,4.584,4.584,0,0,1-1.25-3.6V28.039a5.1,5.1,0,0,1,1.25-3.6,5.1,5.1,0,0,1,3.6-1.25h6.838c.441,0,.662.221.662.588V37.745c0,.441-.221.588-.662.588h-2.5c-.441,0-.588-.22-.588-.588v-.956h-.147a2.055,2.055,0,0,1-1.177,1.176A3.341,3.341,0,0,1,99.641,38.333Zm3.015-5.441V26.863c0-.221-.147-.3-.368-.3H99.494a1.888,1.888,0,0,0-1.323.368A2.18,2.18,0,0,0,97.8,28.26v5.073a1.893,1.893,0,0,0,.368,1.324,1.684,1.684,0,0,0,1.323.367h1.1C101.921,35.1,102.656,34.363,102.656,32.892Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_69"
											data-name="Path 69"
											d="M111.994,38.333h-2.5c-.441,0-.588-.22-.588-.588V23.921c0-.441.22-.588.588-.588h2.5c.441,0,.588.221.588.588v.956h.147a3.012,3.012,0,0,1,1.177-1.176,4.07,4.07,0,0,1,1.912-.441h.808a3.975,3.975,0,0,1,3.677,1.911,3.626,3.626,0,0,1,3.456-1.911h.882a4.614,4.614,0,0,1,3.456,1.25,4.838,4.838,0,0,1,1.177,3.6v9.632c0,.441-.221.588-.589.588h-2.5c-.441,0-.588-.22-.588-.588V28.26a1.893,1.893,0,0,0-.368-1.324,1.684,1.684,0,0,0-1.323-.368h-.735c-1.4,0-2.059.736-2.059,2.28v8.9c0,.441-.221.588-.662.588h-2.5c-.441,0-.588-.22-.588-.588V28.26a1.893,1.893,0,0,0-.368-1.324,1.784,1.784,0,0,0-1.323-.368h-.956c-1.4,0-2.059.736-2.059,2.28v8.9C112.656,38.186,112.435,38.333,111.994,38.333Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_70"
											data-name="Path 70"
											d="M60.891,19.363H54.935c-.441,0-.588.22-.588.588V37.745c0,.441.221.588.588.588h5.956a6.42,6.42,0,0,0,6.25-6.4V25.76A6.42,6.42,0,0,0,60.891,19.363Zm2.427,12.573a2.722,2.722,0,0,1-2.574,2.647H58.1V23.113h2.721a2.67,2.67,0,0,1,2.573,2.647v6.176Z"
											fill="#45e5d9"
										/>
									</g>
									<g id="Group_139" data-name="Group 139">
										<path
											id="Path_71"
											data-name="Path 71"
											d="M39.2,17.157h-.882a.2.2,0,0,1-.221-.221V10.613a.2.2,0,0,1,.221-.221H39.2a.2.2,0,0,1,.221.221v.367h.073a.758.758,0,0,1,.368-.441,1.713,1.713,0,0,1,.588-.147h.441a1.514,1.514,0,0,1,1.25.441,1.729,1.729,0,0,1,.441,1.25v1.912a1.617,1.617,0,0,1-.441,1.25,1.287,1.287,0,0,1-1.176.515h-.441a1.4,1.4,0,0,1-.589-.147.666.666,0,0,1-.441-.442h-.073v1.765A.2.2,0,0,1,39.2,17.157Zm.956-2.574H40.6a1.057,1.057,0,0,0,.515-.147.624.624,0,0,0,.147-.441V12.157a1.068,1.068,0,0,0-.147-.515.855.855,0,0,0-.515-.147h-.441c-.515,0-.735.294-.735.809v1.47C39.421,14.289,39.641,14.583,40.156,14.583Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_72"
											data-name="Path 72"
											d="M45.3,15.76h-.221a1.614,1.614,0,0,1-1.25-.442,1.729,1.729,0,0,1-.441-1.25V12.083a1.617,1.617,0,0,1,.441-1.25,1.731,1.731,0,0,1,1.25-.441h2.427a.2.2,0,0,1,.22.221v4.926a.2.2,0,0,1-.22.221h-.883a.2.2,0,0,1-.22-.221v-.368h-.074a.662.662,0,0,1-.441.442A1.39,1.39,0,0,1,45.3,15.76Zm1.1-1.986V11.642a.158.158,0,0,0-.147-.147H45.3a.617.617,0,0,0-.441.147.668.668,0,0,0-.221.515v1.838a1.071,1.071,0,0,0,.147.515.626.626,0,0,0,.441.147H45.6A.832.832,0,0,0,46.406,13.774Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_73"
											data-name="Path 73"
											d="M50.009,15.76h-.883a.2.2,0,0,1-.22-.221V10.613a.2.2,0,0,1,.22-.221h.883a.2.2,0,0,1,.22.221v.367H50.3a.665.665,0,0,1,.441-.441,1.724,1.724,0,0,1,.588-.147H51.7a.2.2,0,0,1,.221.221v.735c0,.073,0,.147-.074.147a.255.255,0,0,1-.147.073h-.735c-.515,0-.736.3-.736.809v3.162A.2.2,0,0,1,50.009,15.76Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_74"
											data-name="Path 74"
											d="M52.876,11.495h-.441c-.073,0-.147,0-.147-.074a.255.255,0,0,1-.073-.147v-.735a.2.2,0,0,1,.22-.221h.441a.158.158,0,0,0,.148-.147V9.215a.194.194,0,0,1,.22-.22h.882a.2.2,0,0,1,.221.22v1.03a.159.159,0,0,0,.147.147H55.3a.2.2,0,0,1,.221.221v.735c0,.073,0,.147-.074.147a.259.259,0,0,1-.147.073h-.809a.159.159,0,0,0-.147.147v2.353a.627.627,0,0,0,.147.442.862.862,0,0,0,.515.147h.441a.2.2,0,0,1,.221.22v.736c0,.073,0,.147-.074.147a.255.255,0,0,1-.147.073h-.662a1.917,1.917,0,0,1-1.323-.441,1.729,1.729,0,0,1-.441-1.25v-2.5A.158.158,0,0,0,52.876,11.495Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_75"
											data-name="Path 75"
											d="M61.406,15.76H60.45a1.614,1.614,0,0,1-1.25-.442,1.729,1.729,0,0,1-.441-1.25V12.083a1.617,1.617,0,0,1,.441-1.25,1.729,1.729,0,0,1,1.25-.441h.956a1.729,1.729,0,0,1,1.25.441,1.729,1.729,0,0,1,.441,1.25v1.912a1.729,1.729,0,0,1-.441,1.25A1.513,1.513,0,0,1,61.406,15.76Zm-.735-1.177h.514a1.057,1.057,0,0,0,.515-.147c.074-.073.074-.221.074-.441V12.157a.622.622,0,0,0-.148-.442.7.7,0,0,0-.514-.147H60.6a.52.52,0,0,0-.588.589v1.838a.619.619,0,0,0,.147.441C60.229,14.583,60.45,14.583,60.671,14.583Z"
											fill="#45e5d9"
										/>
										<path
											id="Path_76"
											data-name="Path 76"
											d="M65.671,10.392h.808c.074,0,.147,0,.147.073a.258.258,0,0,1,.074.148v.735a.2.2,0,0,1-.221.22h-.808a.158.158,0,0,0-.147.147v3.9a.2.2,0,0,1-.221.22h-.882a.2.2,0,0,1-.221-.22V11.642a.158.158,0,0,0-.147-.147h-.515a.2.2,0,0,1-.22-.221v-.735c0-.074,0-.147.073-.147a.258.258,0,0,1,.147-.074h.515a.158.158,0,0,0,.147-.147v-.147a1.617,1.617,0,0,1,.441-1.25,1.729,1.729,0,0,1,1.25-.441h.588c.074,0,.147,0,.147.074a.254.254,0,0,1,.074.147v.735a.2.2,0,0,1-.221.221h-.294a.621.621,0,0,0-.441.147.7.7,0,0,0-.147.514v.147A.072.072,0,0,0,65.671,10.392Z"
											fill="#45e5d9"
										/>
									</g>
								</svg>
							</a>
						</div>
					</div>
					<div className="col-md-3">
						<div className="copyrights">
							&copy; {new Date().getFullYear()} When{" "}
							<Link to="/polityka-prywatnosci/">
								Polityka prywatności
							</Link>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
