import React from "react";
import { Link } from "gatsby";

const createPrevLinks = (pathPrefix, index, position) => {
  if (index - position > 0)
    return (
      <li className="pagination-item">
        <Link
          to={
            index - position > 1
              ? `/${pathPrefix}/page/${index - position}`
              : `/${pathPrefix}`
          }
        >
          {index - position}
        </Link>
      </li>
    );
};

const createNextLinks = (pathPrefix, index, position, pageCount) => {
  if (index + position <= pageCount)
    return (
      <li className="pagination-item">
        <Link to={`/${pathPrefix}/page/${index + position}`}>
          {index + position}
        </Link>
      </li>
    );
};

const Pagination = ({ first, last, index, pageCount, pathPrefix }) => {
  return (
    <ul className="custom_pagination">
      <li>
        {!first && (
          <Link
            to={index > 2 ? `/${pathPrefix}/page/${index - 1}` : `/${pathPrefix}`}
            className="btn_pagination prev link-line dark"
          >
            poprzednia
          </Link>
        )}
      </li>

      {Array(2)
        .fill(null)
        .map((item, i) => createPrevLinks(pathPrefix, index, 2 - i))}
      <li className="pagination-item active">
        <Link to={index > 1 ? `/${pathPrefix}/page/${index}` : `${pathPrefix}`}>
          {index}
        </Link>
      </li>
      {Array(2)
        .fill(null)
        .map((item, i) => createNextLinks(pathPrefix, index, i + 1, pageCount))}
      <li>
        {!last && (
          <Link
            to={`/${pathPrefix}/page/${index + 1}`}
            className="btn_pagination next link-line dark right"
          >
            następna
          </Link>
        )}
      </li>
    </ul>
  );
};

export default Pagination;
