import React from "react";

const Loader = () => {
  return (
    <div className="loader normal">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="120.419"
        height="41.299"
        viewBox="0 0 120.419 41.299"
        className="logo-text"
      >
        {/* <path
          id="Path_77"
          className="letter letter--w"
          data-name="Path 77"
          d="M206.6,112.661h-5.075a1.042,1.042,0,0,1-1.069-.935l-4.452-20.836q-.178-.935.668-.935h4.274a.942.942,0,0,1,1.068.935l2,15.226h.356l3.161-15.226a.95.95,0,0,1,1.025-.935h3.918a1.029,1.029,0,0,1,1.113.935l3.071,15.226h.356l2.048-15.226a.972.972,0,0,1,1.068-.935h4.274q.89,0,.668.935l-4.452,20.836a1.006,1.006,0,0,1-1.069.935h-5.076a1,1,0,0,1-1.068-.935L210.7,96.678h-.356l-2.671,15.048A.972.972,0,0,1,206.6,112.661Z"
          transform="translate(-195.975 -81.363)"
          fill="#721AAD"
        />
        <path
          id="Path_78"
          className="letter letter--h"
          data-name="Path 78"
          d="M240.508,110.738h-3.784a.827.827,0,0,1-.936-.935V80.375a.827.827,0,0,1,.936-.936h3.784a.827.827,0,0,1,.935.936V90.436h.178q.979-2.4,4.363-2.4h1.424q6.99,0,6.99,7.257V109.8q0,.935-.98.935H249.68a.826.826,0,0,1-.936-.935V95.556a2.216,2.216,0,0,0-2.627-2.627h-1.6q-3.073,0-3.072,3.384V109.8A.826.826,0,0,1,240.508,110.738Z"
          transform="translate(-203.258 -79.439)"
          fill="#721AAD"
        />
        <path
          id="Path_79"
          className="letter letter--e"
          data-name="Path 79"
          d="M280.926,112.661h-9.839a7.451,7.451,0,0,1-5.431-1.869,7.19,7.19,0,0,1-1.914-5.387V97.213a7.19,7.19,0,0,1,1.914-5.387,7.447,7.447,0,0,1,5.431-1.869H274.6a7.452,7.452,0,0,1,5.432,1.869,7.2,7.2,0,0,1,1.914,5.387v5.654a.826.826,0,0,1-.935.935H269.841q-.534,0-.534.489v.98a2.725,2.725,0,0,0,.6,1.981,2.8,2.8,0,0,0,2.026.6h8.993c.623,0,.935.328.935.98v2.938a.961.961,0,0,1-.2.69A1.034,1.034,0,0,1,280.926,112.661Zm-11.085-12.644H275.9a.433.433,0,0,0,.489-.49V97.391a2.158,2.158,0,0,0-2.582-2.582h-1.869a2.159,2.159,0,0,0-2.583,2.582v2.137A.433.433,0,0,0,269.841,100.018Z"
          transform="translate(-208.37 -81.363)"
          fill="#721AAD"
        />
        <path
          id="Path_80"
          className="letter letter--n"
          data-name="Path 80"
          d="M295.921,112.661h-3.785a.826.826,0,0,1-.935-.935V90.891a.826.826,0,0,1,.935-.935h3.785a.826.826,0,0,1,.935.935V92.36h.178q.978-2.4,4.363-2.4h1.424q6.988,0,6.99,7.257v14.514q0,.935-.98.935h-3.74a.826.826,0,0,1-.935-.935V97.48a2.217,2.217,0,0,0-2.627-2.627h-1.6q-3.071,0-3.071,3.384v13.49A.826.826,0,0,1,295.921,112.661Z"
          transform="translate(-213.393 -81.363)"
          fill="#721AAD"
        /> */}
        <path
          id="path14"
          d="M37.9,10L31,35h-7.5l-2.3-8.4l-2.2-10.1l0,0l-2.3,10.1L14.4,35H6.9L0,10h6.5l2.1,7.9l2.2,10l2.2-10
	L15,10h7.8l2.1,7.9l2.2,10l2.2-10l2.1-7.9H37.9"
          className="letter letter--w"
          fill="#721AAD"
        />
        <path
          id="path16"
          d="M80.6,14.8c3.1,0,5.2,1.9,5.9,4.6h-12C75.4,16.7,77.4,14.8,80.6,14.8z M80.8,30.2
	c-3.4,0-6.1-2-6.5-6h19.2c0.6-8.2-4.5-14.7-12.8-14.7c-7.6,0-13,5.6-13,13.1c0,7.5,5.4,13,13.2,13c5.4,0,10.4-2.6,12.2-8.5h-6.7
	C85.4,29,83.3,30.2,80.8,30.2"
          fill="#721AAD"
          className="letter letter--e"
        />
        <path
          d="M111,9.4c-7.8,0-14.3,14.7-14.3,25.5h6.5V21.9c0-4.1,2-6.2,5.6-6.2c3.4,0,4.7,1.8,4.7,5.1V35h6.5V19.3
	C120,13.2,116.6,9.4,111,9.4 M96.7,16.5c3.6,0,6.5-2.9,6.5-6.5h-6.5V16.5"
          fill="#721AAD"
          className="letter letter--n"
        />
        <path
          d="M54.7,9.4C47,9.4,40.5,24.2,40.5,35H47V21.9c0-4.1,2-6.2,5.6-6.2c3.4,0,4.7,1.8,4.7,5.1V35h6.5V19.3
	C63.7,13.2,60.3,9.4,54.7,9.4 M40.5,0v16.5c3.6,0,6.5-2.9,6.5-6.5V0H40.5"
          fill="#721AAD"
          className="letter letter--h"
        />
      </svg>
      <div className="dots">
        <span className="dots__single-dot single dot single-dot--4"></span>
        <span className="dots__single-dot single dot single-dot--2"></span>
        <span className="dots__single-dot single dot single-dot--1"></span>
        <span className="dots__single-dot single dot single-dot--3"></span>
        <span className="dots__single-dot single dot single-dot--5"></span>
      </div>
    </div>
  );
};

export default Loader;
