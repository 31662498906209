import React from "react";
import Helmet from "react-helmet";

const SEO = ({
	yoast_wpseo_canonical,
	yoast_wpseo_metadesc,
	yoast_wpseo_title,
	schemaMarkup,
	title,
	description,
	href,
	lang,
	noindex,
	next,
	prev,
	customCanonical,
	ogImage,
	updated_title,
}) => {
	let seo_title = yoast_wpseo_title || title;
	seo_title = `${seo_title} ${seo_title.includes("When") ? "" : "| When"}`;
	return (
		<Helmet title={seo_title}>
			<html lang="pl-PL" />
			<meta
				name="description"
				content={
					description ||
					"Win-win digital agency. Ekspercka wiedza, partnerska współpraca, imponujące wyniki. Wygrywaj z nami! #whenWEwin"
				}
			/>
			{customCanonical && <link rel="canonical" href={customCanonical} />}
			<link rel="profile" href="https://gmpg.org/xfn/11" />
			<meta name="msapplication-TileColor" content="#2d89ef" />
			<meta name="theme-color" content="#ffffff" />
			<meta property="og:locale" content="en_US" />
			<meta property="og:type" content="website" />
			{noindex === true ? <meta name="robots" content="noindex" /> : ""}
			{schemaMarkup && (
				<script type="application/ld+json">
					{JSON.stringify(schemaMarkup)}
				</script>
			)}
			<meta
				property="og:description"
				content="Win-win digital agency. Ekspercka wiedza, partnerska współpraca, imponujące wyniki. Wygrywaj z nami! #whenWEwin"
			/>
			<meta property="og:site_name" content="When" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta
				name="twitter:description"
				content="Win-win digital agency. Ekspercka wiedza, partnerska współpraca, imponujące wyniki. Wygrywaj z nami! #whenWEwin"
			/>
			{next && <link rel="next" href={`${href}${next}`} />}
			{prev && <link rel="prev" href={`${href}${prev}`} />}
			<meta
				property="og:title"
				content={`${yoast_wpseo_title || title}`}
			/>
			<meta property="og:url" content={href} />
			<meta
				property="og:image"
				content={
					ogImage ? ogImage : require("../assets/img/og_image.png")
				}
			/>
			<meta name="twitter:title" content={`${updated_title}`} />
		</Helmet>
	);
};

export default SEO;
