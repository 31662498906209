import "../styles/theme.scss";

import React, { Component } from "react";
import { CookiesProvider, withCookies } from "react-cookie";
import scrollToElement from "scroll-to-element";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { Header, Footer, SEO } from "./";

if (typeof window !== "undefined") {
	require("smooth-scroll")('a[href*="#"]');
}

const { detect } = require("detect-browser");
const browser = detect();
class Layout extends Component {
	componentDidMount() {
		setTimeout(() => {
			this.mainMargin();
		}, 500);
		window.addEventListener("resize", this.mainMargin);
	}

	mainMargin() {
		const footer = document.getElementsByTagName("footer");
		if (!footer) return false;
		const footerHeight = footer[0].clientHeight;
		document.getElementsByClassName(
			"main"
		)[0].style.paddingTop = `${footerHeight}px`;

		if (browser.name === "firefox") {
			document.getElementsByClassName(
				"main"
			)[0].style.marginTop = `-${footerHeight}px`;
		} else {
			document.getElementsByClassName(
				"main"
			)[0].style.marginTop = `-${footerHeight}px`;
		}
		document.getElementsByClassName(
			"main"
		)[0].style.marginBottom = `${footerHeight}px`;
	}

	render() {
		const { children, seo, header } = this.props;
		return (
			<CookiesProvider>
				<GoogleReCaptchaProvider reCaptchaKey="6LeEoxoaAAAAAAptxgMCXF2le1FRDd6yDPSUHbH7">
					<script
						id="63edf6948cdbf706ed37471e"
						src="https://dashboard.chatfuel.com/integration/fb-entry-point.js"
						asyncdefer
					></script>
					<SEO {...seo} />
					<Header {...seo} {...header} scroll={this.props.scroll} />
					<main className="main">
						{children}
						<button
							className="scrollTo"
							onClick={() => scrollToElement("#top")}
							aria-label="Scroll To Element"
						/>
					</main>
					<Footer {...seo} />
				</GoogleReCaptchaProvider>
			</CookiesProvider>
		);
	}
}

export default withCookies(Layout);
